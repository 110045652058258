import $ from 'jquery'

function jumpTo (id, offset = 0) {
  if ($(id).length) {
    $('html, body').animate({
      scrollTop: $(id).offset().top - offset
    }, 600, 'swing')
  }
}

export default function init () {
  $(document).on('click', '#nav-contact', function (e) {
    e.preventDefault()
    let targetID = $(this).attr('href')
    let offset = $(this).data('offset')
    jumpTo(targetID, offset)
  })
  $(document).on('click', '[data-jump]', function (e) {
    e.preventDefault()
    let targetID = $(this).attr('href')
    let offset = $(this).data('offset')
    jumpTo(targetID, offset)
  })
}
